import { Col, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import ENV from "utils/env";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";

const firstTabColumns = [
  {
    header: "Şehir",
    key: "city",
  },
  {
    header: "Sayı",
    key: "count",
  },
];

export const AttendCountsBasedOnCities: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();

  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/attendCountsBasedOnCities`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }

  const dataSource = response.data.results.map((item: any) => {
    return {
      key: item.city,
      city: item.city,
      count: item.count,
    };
  });

  const totalCount = dataSource.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);

  const columns = [
    {
      title: "Şehir",
      dataIndex: "city",
      key: "city",
      width: "25%",
    },
    {
      title: "Sayı",
      dataIndex: "count",
      key: "count",
    },
  ];

  const updatedAt = response.data.updatedAt;
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={firstTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 25, disabled: false }}
        title={() => (
          <div>
            <Row>
              <Col span={6}>Toplam Katılım Sayısı:</Col>
              <Col span={6}>
                <b>{totalCount}</b>
              </Col>
            </Row>
            <Row>
              <Col span={6}>Son Güncelleme Tarihi:</Col>
              <Col span={6}>
                {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
              </Col>
              <Col span={6} style={{ fontWeight: 600 }}>
                Veriler her 5 dakikada bir güncellenmektedir.
              </Col>
              <Col span={12} />
            </Row>
          </div>
        )}
      />
    </div>
  );
};
