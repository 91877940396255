import React, { useState } from "react";
import { file2Base64, GetOneResponse } from "@pankod/refine-core";

import {
  Form,
  Input,
  Select,
  useSelect,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  Upload,
  Row,
  Col,
  Icons,
  getValueFromEvent,
} from "@pankod/refine-antd";

import { QueryObserverResult} from "react-query";

import { Tags, Assets } from "interfaces";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  queryResult: QueryObserverResult<GetOneResponse<Assets.AssetItem>, unknown>;
};

export const AssetsEdit: React.FC<Props> = ({
  formProps,
  modalProps,
  queryResult,
}) => {
  const [base64Image, setBase64Image] = useState<string | undefined>();

  const renderDraggerContent = () => {
    if (base64Image) {
      return <img src={base64Image} width="100%" alt="" />;
    }

    if (queryResult.data?.data.path) {
      return <img src={queryResult.data?.data.path} width="100%" alt="" />;
    }

    return (
      <div style={{ marginBottom: "112px", marginTop: "112px" }}>
        <p className="ant-upload-drag-icon">
          <Icons.InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Yüklemek için dosyayı bu alana tıklayın veya sürükleyin
        </p>
      </div>
    );
  };

  const { selectProps: tagsFilterSelectProps } = useSelect<Tags.TagItem>({
    resource: "tags",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult.data?.data.tags.map((item) => item.id),
  });

  const onChange = async ({ fileList: newFileList }) => {
    const base64String = await file2Base64(newFileList[0]);
    setBase64Image(base64String);
  };

  const requestEdit = async (values) => {
    const { image } = values;

    let base64String;
    for (const file of image) {
      if (file.originFileObj) {
        base64String = await file2Base64(file);
      }
    }

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        image: base64String,
      })
    );
  };

  return (
    <Modal {...modalProps} title="Ürün Ekle" okText="Kaydet ve Kapat">
      <Form {...formProps} onFinish={requestEdit} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item>
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                getValueProps={(data) => [data] as any}
                noStyle
              >
                <Upload.Dragger
                  accept=".png"
                  beforeUpload={() => false}
                  multiple={false}
                  maxCount={1}
                  onChange={onChange}
                  showUploadList={false}
                  fileList={[]}
                >
                  {renderDraggerContent()}
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <span
              style={{
                fontSize: "12px",
                color: "#8C8C8C",
                fontWeight: 300,
                letterSpacing: "0.5px",
              }}
            >
              min
              <strong> 1080x1080 </strong>
              transparan
              <strong> .png </strong>
              olmalı
            </span>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Dosya İsmi"
              name="name"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Dosya ismi alanı boş olamaz.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Durumu"
              name="isActive"
              rules={[
                {
                  required: true,
                  message: "Dosya durumunu seçiniz.",
                },
              ]}
            >
              <Radio.Group
                defaultValue={1}
                options={[
                  {
                    label: "Aktif",
                    value: true,
                  },
                  {
                    label: "Pasif",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item<Assets.AssetItem>
              label="Etiketler"
              name="tags"
              rules={[
                {
                  required: true,
                  message: "Dosya için tag seçiniz.",
                },
              ]}
              getValueProps={(tags?: { id: string }[]) => {
                return { value: tags?.map((store) => store.id) };
              }}
              getValueFromEvent={(args: string[]) => {
                return args.map((item) => ({
                  id: item,
                }));
              }}
            >
              <Select
                {...tagsFilterSelectProps}
                style={{ width: "100%" }}
                mode="multiple"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
