import React, { useState } from "react";
import {
  useTranslate,
  useCustom,
  HttpError,
  GetListResponse,
  GetOneResponse,
  useGetIdentity,
} from "@pankod/refine-core";

import {
  Input,
  Modal,
  Skeleton,
  Icons,
  Tag,
  Button,
  notification,
  Row,
  Col,
  ModalProps,
} from "@pankod/refine-antd";

import dayjs from "dayjs";
import { QueryObserverResult } from "react-query";

import { Contents } from "interfaces";
import { ContentStatus, RoleTypes } from "interfaces/enums";
import { renderDisplayValue } from "definitions";
import { ContentPreview } from "components/contentpreview";
import ENV from "utils/env";

type Props = {
  queryResult: QueryObserverResult<
    GetOneResponse<Contents.ContentItem>,
    unknown
  >;
  tableQueryResult: QueryObserverResult<
    GetListResponse<Contents.ContentItem>,
    unknown
  >;
  modalProps: ModalProps;
};

export const ModerationShow: React.FC<Props> = ({
  queryResult,
  tableQueryResult,
  modalProps,
}) => {
  const [rejectFormVisible, setRejectFormVisible] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState("");
  const { data: user } = useGetIdentity();
  const { isFetching, data } = queryResult;

  const contentMutationSuccess = () => {
    tableQueryResult.refetch();

    modalProps.onCancel(undefined);
    notification.success({
      message: "İçerik durumu başarıyla güncellendi.",
    });
  };

  const { refetch: approveContent, isFetching: approveContentIsFetching } =
    useCustom<unknown, HttpError, unknown, { status: ContentStatus }>({
      url: `${ENV.API_URL}/api/contents/status/${data?.data.id}`,
      method: "patch",
      config: {
        payload: {
          status: ContentStatus.PUBLISHED,
        },
      },
      queryOptions: {
        retry: false,
        enabled: false,
        onSuccess: contentMutationSuccess,
      },
    });

  const { refetch: stopContent, isFetching: stopContentIsFetching } = useCustom<
    unknown,
    HttpError,
    unknown,
    { status: ContentStatus }
  >({
    url: `${ENV.API_URL}/api/contents/status/${data?.data.id}`,
    method: "patch",
    config: {
      payload: {
        status: ContentStatus.STOPPED,
      },
    },
    queryOptions: {
      retry: false,
      enabled: false,
      onSuccess: contentMutationSuccess,
    },
  });

  const { refetch: rejectContent, isFetching: rejectContentIsFetching } =
    useCustom<
      unknown,
      HttpError,
      unknown,
      { status: ContentStatus; rejectReason: string }
    >({
      url: `${ENV.API_URL}/api/contents/status/${data?.data.id}`,
      method: "patch",
      config: {
        payload: {
          status: ContentStatus.REJECTED,
          rejectReason,
        },
      },
      queryOptions: {
        retry: false,
        enabled: false,
        onSuccess: contentMutationSuccess,
      },
    });

  const t = useTranslate();

  const renderStatus = () => {
    const { status } = data.data;
    const text = t(`Enums.ContentStatus.${status}`);
    switch (status) {
      case "waiting_approval":
        return (
          <Tag color="processing" icon={<Icons.ReloadOutlined />}>
            {text}
          </Tag>
        );
      case "published":
        return (
          <Tag color="success" icon={<Icons.CheckOutlined />}>
            {text}
          </Tag>
        );
      case "rejected":
        return (
          <Tag color="warning" icon={<Icons.StopOutlined />}>
            {text}
          </Tag>
        );
      case "stopped":
        return (
          <Tag color="red" icon={<Icons.WarningOutlined />}>
            {text}
          </Tag>
        );

      default:
        return <Tag>{status}</Tag>;
    }
  };

  const renderRejectForm = () => {
    if (rejectFormVisible) {
      return (
        <>
          <h3>İçeriği Reddet</h3>
          <Input.TextArea
            placeholder="Red nedenini yazınız"
            required
            style={{ marginBottom: 4 }}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setRejectReason(event.target.value)
            }
            rows={4}
            value={rejectReason}
          />
          <Button
            shape="round"
            type="primary"
            style={{ marginRight: 4 }}
            onClick={() => rejectContent()}
            loading={rejectContentIsFetching}
          >
            Reddet
          </Button>
          <Button
            shape="round"
            type="link"
            onClick={() => setRejectFormVisible(false)}
          >
            İptal Et
          </Button>
        </>
      );
    }
    return null;
  };

  const renderActions = () => {
    const { status } = data.data;

    // disable actions for REPORTER users
    if (user.role.type === RoleTypes.REPORTER) {
      return <span />;
    }

    switch (status) {
      case ContentStatus.WAITING_APPROVAL:
        return (
          <>
            {renderRejectForm()}
            {!rejectFormVisible && (
              <>
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => approveContent()}
                  loading={approveContentIsFetching}
                >
                  Onayla
                </Button>
                <Button
                  onClick={() => setRejectFormVisible(true)}
                  size="large"
                  danger
                  shape="round"
                >
                  Reddet
                </Button>
              </>
            )}
          </>
        );

      case ContentStatus.PUBLISHED:
        return (
          <Button
            size="large"
            danger
            shape="round"
            onClick={() => stopContent()}
            loading={stopContentIsFetching}
          >
            Yayını Durdur
          </Button>
        );

      default:
        break;
    }

    return null;
  };

  const renderParameters = () => {
    if (data.data) {
      const templateParams = data.data.template.parameters;
      const contentParams = data.data.parameters;
      return templateParams.map((item) => {
        // hide backofficelist
        if (item.hideBackofficeList) return null;

        const contentParam: any = contentParams.find(
          (contentParamsItem: any) => contentParamsItem.name === item.name
        );

        if (!contentParam) return null;

        return (
          <p key={item.name}>
            <strong style={{ marginRight: 4 }}>{`${item.label}: `}</strong>
            {renderDisplayValue(item.type, contentParam.value)}
          </p>
        );
      });
    }

    return null;
  };

  const renderContent = () => {
    if (isFetching) {
      return (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      );
    }

    if (data) {
      return (
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12}>
            <ContentPreview
              parameters={data.data.parameters}
              template={data.data.template}
            />
          </Col>
          <Col sm={24} md={12}>
            <div style={{ marginBottom: 12 }}>
              <h2>{data.data.name}</h2>
              <p>
                <strong style={{ marginRight: 4 }}>İçerik:</strong>
                {data.data.template.name}
              </p>
              <p>
                <strong style={{ marginRight: 4 }}>Oluşturan:</strong>
                {data.data.user.fullName}
              </p>
              <p>
                <strong style={{ marginRight: 4 }}>Mağaza:</strong>
                {data.data.shop.name}
              </p>
              <p>
                <strong style={{ marginRight: 4 }}>Gösterilme Ağırlığı:</strong>
                {data.data.weight}
              </p>
              <p>
                <strong style={{ marginRight: 4 }}>Durum:</strong>
                {renderStatus()}
              </p>
              {data.data.status === ContentStatus.REJECTED && (
                <p>
                  <strong style={{ marginRight: 4 }}>Red Nedeni:</strong>
                  {data.data.rejectReason}
                </p>
              )}
              {renderParameters()}
              <p>
                <strong style={{ marginRight: 4 }}>Başlangıç Tarihi:</strong>
                {dayjs(data.data.startDate).format("D MMMM YYYY")}
              </p>
              <p>
                <strong style={{ marginRight: 4 }}>Bitiş Tarihi:</strong>
                {dayjs(data.data.expireDate).format("D MMMM YYYY")}
              </p>
            </div>
            <div>{renderActions()}</div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <Modal {...modalProps} footer={null} width={800} title="İçerik onay">
      {renderContent()}
    </Modal>
  );
};
