import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";


import {
  List,
  Table,
  Tag,
  useTable,
  EditButton,
  Icons,
  FilterDropdown,
  Typography,
  useSelect,
  Select,
  Radio,
  Input,
} from "@pankod/refine-antd";


import { Shops, Contents } from "interfaces";
import { useState } from "react";
import { ContentStatus } from "interfaces/enums";
import { TemplateChoiceModal } from "./components/templatechoice";
import { DeviceSyncWarningModal } from "./components/devicesyncwarning";

const { Title } = Typography;

export const ContentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Contents.ContentItem>({

  });
  const [visibleChoiceTemplate, setVisibleChoiceTemplate] = useState(false);

  const t = useTranslate();

  const { selectProps: shopsFilterSelectProps } = useSelect<Shops.ShopItem>({
    resource: "shops",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <>
      <List title="İçerik Yönetimi" createButtonProps={{
        type: "primary",
        size: "large",
        shape: "round",
        icon: <Icons.PlusOutlined />,
        children: "Yeni İçerik Yayınla",
        onClick: () => setVisibleChoiceTemplate(true),
      }}>
        <Table
          {...tableProps}
          scroll={{ x: 1 }}
          rowKey="id"
        >
          <Table.Column
            dataIndex={["template", "name"]}
            title="Kampanya"
          />

          <Table.Column
            dataIndex="name"
            title="Başlık"
          />
          <Table.Column<Contents.ContentItem>
            dataIndex={["shop", "name"]}
            title="Bağlı Mağaza"
            key="shop.name"
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz mağazayı yazın</Title>
                  <Select
                    {...shopsFilterSelectProps}
                    style={{ width: "100%" }}
                    mode="multiple"
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Contents.ContentItem>
            dataIndex={["shop", "code"]}
            title="Bağlı Mağaza Kodu"
            key="shop.code"
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz mağazayı kodunu yazın</Title>
                  <Input />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Contents.ContentItem>
            dataIndex="status"
            title="Durum"
            render={(_, record) => {

              const { status } = record;

              const text = t(`Enums.ContentStatus.${status}`);
              switch (status) {
                case "waiting_approval":
                  return (
                    <Tag color="processing" icon={<Icons.ReloadOutlined />}>
                      {text}
                    </Tag>
                  );
                case "published":
                  return (
                    <Tag color="success" icon={<Icons.CheckOutlined />}>
                      {text}
                    </Tag>
                  );
                case "rejected":
                  return (
                    <Tag color="warning" icon={<Icons.StopOutlined />}>
                      {text}
                    </Tag>
                  );
                case "stopped":
                  return (
                    <Tag color="red" icon={<Icons.WarningOutlined />}>
                      {text}
                    </Tag>
                  );

                case "done":
                  return (
                    <Tag color="lime" icon={<Icons.CheckOutlined />}>
                      {text}
                    </Tag>
                  );

                default:
                  return <Tag>{status}</Tag>;
              }
            }}
            filterDropdown={props => {
              const options = [
                { label: "Onay Bekliyor", value: ContentStatus.WAITING_APPROVAL },
                { label: "Yayında", value: ContentStatus.PUBLISHED },
                { label: "Reddedildi", value: ContentStatus.REJECTED },
                { label: "Durduruldu", value: ContentStatus.STOPPED },
                { label: "Tamamlandı", value: ContentStatus.DONE },
              ];


              return <FilterDropdown {...props}>
                <Title level={5}>Durum</Title>
                <Radio.Group style={{ width: 150 }} options={options} />
              </FilterDropdown>


            }}
          />
          <Table.Column<Contents.ContentItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <EditButton
                size="small"
                icon={<Icons.EditOutlined />}
                recordItemId={record.id}
              >
                Düzenle
              </EditButton>
            )}
          />
        </Table>
      </List>
      <TemplateChoiceModal
        title="Bir Şablon Seçin"
        setVisibleChoiceTemplate={setVisibleChoiceTemplate}
        width="800px"
        visible={visibleChoiceTemplate}
        onCancel={() => setVisibleChoiceTemplate(false)}
        footer={null}
      />
      <DeviceSyncWarningModal />
    </>
  );
};
