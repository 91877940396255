import {
  Affix,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Typography,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { Contents, Roles, Shops, Templates } from "interfaces";
import {
  IResourceComponentsProps,
  useGetIdentity,
  useOne,
} from "@pankod/refine-core";
import React, { useEffect, useState } from "react";
import { filterParametersFields, getBounceAreaFields } from "definitions";

import { ContentPreview } from "components/contentpreview";
import { FormBuilder } from "components/formbuilder";
import { RoleTypes } from "interfaces/enums";
import dayjs from "dayjs";
import { useProductTour } from "hooks/useProductTour";

export const ContentCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm({
    redirect: "list",
    successNotification: {
      message: "Başarılı",
      description: "İçerik başarıyla yaratıldı",
      type: "success",
    },
  });

  const { data: user, isFetched: userIsFetched } = useGetIdentity<{
    fullName: string;
    gsm: string;
    email: string;
    role: Roles.RoleItem;
    shops: [{ name: string; code: string; id: string; cityName: string }];
  }>();

  const [selectedShopId, setSelectedShopId] = useState<string | undefined>();

  const authShopManager = user && user.role.type === RoleTypes.SHOP_MANAGER;

  const [templateParams, setTemplateParams] = React.useState<
    Contents.ContentParameters[]
  >([]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const { data: templatesRetrieveOne, isFetched: templateIsFetched } =
    useOne<Templates.TemplateItem>({
      resource: "templates",
      id: params.id,
    });

  const startTour = useProductTour();

  useEffect(() => {
    const productParam = templateParams.filter(
      (param) => param.name === "product"
    );

    const selectedProduct = productParam[0]?.value;
    if (selectedProduct) {
      startTour({
        templateResourceSlug: templatesRetrieveOne?.data.resourceSlug,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateParams]);

  const { selectProps: shopsSelectProps, queryResult } =
    useSelect<Shops.ShopItem>({
      resource: "shops",
      optionLabel: "name",
      optionValue: "id",
      defaultValue: user?.shops.length > 0 ? user?.shops[0].id : undefined,
    });

  const { data: selectedShop } = useOne<Shops.ShopItem>({
    resource: "shops",
    id: selectedShopId,
    queryOptions: {
      enabled: selectedShopId !== undefined,
    },
  });

  const renderContent = () => {
    if (!templateIsFetched || !userIsFetched) {
      return <Skeleton active />;
    }

    if (templatesRetrieveOne.data) {
      // show / hide shop field

      if (authShopManager) {
        form.setFields([
          {
            name: ["shop", "id"],
            value: user!.shops[0].id,
          },
        ]);
      }

      const { parameters, resourceSlug } = templatesRetrieveOne.data;

      return (
        <Row gutter={[16, 16]}>
          <Col md={10} xl={6}>
            <Affix offsetTop={20}>
              <ContentPreview
                parameters={[
                  ...templateParams,
                  {
                    name: "shopCityName",
                    value: authShopManager
                      ? user!.shops[0].cityName
                      : selectedShop?.data?.cityName,
                  },
                ]}
                template={templatesRetrieveOne.data}
              />
            </Affix>
          </Col>

          <Col md={12} xl={18}>
            <Form
              {...formProps}
              layout="vertical"
              onFinish={(values: any) => {
                const parameters = filterParametersFields(values);

                const startDate = dayjs(values.date[0]).startOf("day").toDate();
                const expireDate = dayjs(values.date[1]).endOf("day").toDate();

                return (
                  formProps.onFinish &&
                  formProps.onFinish({
                    name: values.name,
                    parameters: [
                      ...parameters,
                      {
                        name: "shopCityName",
                        value: authShopManager
                          ? user!.shops[0].cityName
                          : queryResult.data.data.find(
                              (p) => p.id === values.shop.id
                            ).cityName,
                      },
                    ],
                    template: {
                      id: templatesRetrieveOne.data.id,
                    },
                    shop: {
                      id: values.shop.id,
                    },
                    startDate,
                    expireDate,
                    weight: values.weight,
                  })
                );
              }}
            >
              <Card title="Kampanya Bilgileri" style={{ marginBottom: 9 }}>
                <Row gutter={[16, 0]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Kampanya Adı"
                      name="name"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Kampanya Adı boş olamaz.",
                        },
                      ]}
                      tooltip="Bu alanda kampanya ismini değiştirebilirsiniz."
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <Form.Item
                      label="Gösterilme Ağırlığı"
                      name="weight"
                      validateTrigger="onBlur"
                      initialValue={50}
                      rules={[
                        {
                          required: true,
                          message: "Gösterilme ağırlığı boş olamaz.",
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 100,
                          message:
                            "Gösterilme ağırlığı için 1-100 arasında bir değer girilmelidir.",
                        },
                      ]}
                      tooltip="Bu alanda içeriğin gösterilme sıklığını arttırıp azaltabilirsiniz."
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item
                      label="Kampanya Süresi"
                      name="date"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Başlangıç / Bitiş tarihini seçiniz.",
                        },
                      ]}
                      tooltip="Bu alanda kampanyanın yayında kalma tarihlerini ayarlayabilirsiniz."
                    >
                      <DatePicker.RangePicker
                        disabledDate={(current) => {
                          return current && current < dayjs().startOf("day");
                        }}
                        style={{ width: "100%" }}
                        onChange={() => {
                          const params = filterParametersFields(
                            form.getFieldsValue()
                          );
                          setTemplateParams(params);
                        }}
                        format="DD/MM/YYYY"
                        placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                title="Mağaza"
                hidden={authShopManager}
                style={{ marginBottom: 9 }}
              >
                <Row>
                  <Col lg={12}>
                    <Form.Item
                      label="Mağaza"
                      name={["shop", "id"]}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Mağaza seçiniz.",
                        },
                      ]}
                    >
                      <Select
                        {...shopsSelectProps}
                        onChange={(value) => {
                          setSelectedShopId(value as any as string);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              {![
                "product-discount-gift",
                "hr",
                "campaign",
                "double-campaign",
                "sim-1",
                "sim-2",
                "bill-payment-point",
              ].includes(resourceSlug) && (
                <Card title="Başlık" style={{ marginBottom: 9 }}>
                  <Row>
                    <Col lg={12}>
                      <FormBuilder
                        filterFields={["title"]}
                        triggerChange={() => {
                          const params = filterParametersFields(
                            form.getFieldsValue()
                          );
                          setTemplateParams(params);
                        }}
                        parameters={parameters}
                        tooltip="Bu alanda içeriğinizin başlığını değiştirebilirsiniz."
                      />
                    </Col>
                  </Row>
                </Card>
              )}

              {![
                "hr",
                "campaign",
                "double-campaign",
                "sim-1",
                "sim-2",
                "bill-payment-point",
              ].includes(resourceSlug) && (
                <Card title="Ürün" style={{ marginBottom: 9 }}>
                  <Col lg={18}>
                    <FormBuilder
                      filterFields={["product"]}
                      triggerChange={() => {
                        const params = filterParametersFields(
                          form.getFieldsValue()
                        );
                        setTemplateParams(params);
                      }}
                      parameters={parameters}
                    />
                  </Col>
                  {resourceSlug === "product-discount-gift" && (
                    <>
                      <Col lg={18}>
                        <FormBuilder
                          filterFields={["gift"]}
                          triggerChange={() => {
                            const params = filterParametersFields(
                              form.getFieldsValue()
                            );
                            setTemplateParams(params);
                          }}
                          parameters={parameters}
                        />
                      </Col>
                      <Col lg={8}>
                        {form.isFieldTouched("parameters.gift") && (
                          <FormBuilder
                            filterFields={["giftBounceHeight"]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setTemplateParams(params);
                            }}
                            parameters={parameters}
                          />
                        )}
                      </Col>
                    </>
                  )}
                </Card>
              )}

              {[
                "product-discount-percent",
                "product-discount-price",
                "product-discount-gift",
                "campaign",
              ].includes(resourceSlug) && (
                <Card title="Patlangaç" style={{ marginBottom: 9 }}>
                  {getBounceAreaFields(resourceSlug).map((item) => (
                    <Row gutter={[16, 16]}>
                      {item.map((formItem) => (
                        <Col lg={formItem.lg}>
                          <FormBuilder
                            filterFields={[formItem.fieldName]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setTemplateParams(params);
                            }}
                            parameters={parameters}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Card>
              )}

              {"double-campaign" === resourceSlug && (
                <Card title="Patlangaç" style={{ marginBottom: 9 }}>
                  <Typography.Title level={5}>1. Teklif</Typography.Title>
                  {getBounceAreaFields(`${resourceSlug}-1`).map((item) => (
                    <Row gutter={[16, 16]}>
                      {item.map((formItem) => (
                        <Col lg={formItem.lg}>
                          <FormBuilder
                            filterFields={[formItem.fieldName]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setTemplateParams(params);
                            }}
                            parameters={parameters}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}

                  <Typography.Title level={5}>2. Teklif</Typography.Title>
                  {getBounceAreaFields(`${resourceSlug}-2`).map((item) => (
                    <Row gutter={[16, 16]}>
                      {item.map((formItem) => (
                        <Col lg={formItem.lg}>
                          <FormBuilder
                            filterFields={[formItem.fieldName]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setTemplateParams(params);
                            }}
                            parameters={parameters}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Card>
              )}

              {"bill-payment-point" === resourceSlug && (
                <Card title="Fatura Tipleri" style={{ marginBottom: 9 }}>
                  <Row>
                    <Col lg={12}>
                      <FormBuilder
                        filterFields={["billType"]}
                        triggerChange={() => {
                          const params = filterParametersFields(
                            form.getFieldsValue()
                          );
                          setTemplateParams(params);
                        }}
                        parameters={parameters}
                        tooltip="Bu alanda fatura tiplerinizi belirleyebilirsiniz."
                      />
                    </Col>
                  </Row>
                </Card>
              )}

              <Form.Item style={{ marginTop: 12 }}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  style={{ float: "right" }}
                  {...saveButtonProps}
                >
                  Onaya Gönder
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <Card
      style={{ marginBottom: 10 }}
      title={
        <Typography.Title level={3}>İçeriğinizi Oluşturun</Typography.Title>
      }
    >
      {renderContent()}
    </Card>
  );
};
